import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const incentiveList = createApi({
  reducerPath: "incentiveList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Incentives"],
  endpoints: (builder) => ({
    getIncentivesData: builder.query({
      query: ({
        page = 1,
        limit,
        type,
        status,
        individual,
        search,
        pagination = true,
        
      }) => ({
        url: `${Apis.incentive}`,
        params: {
          page,
          limit,
          type,
          status,
          individual,
          search,
          pagination, 
        },
      }),
      providesTags: ["Expenses"],
      keepUnusedDataFor: 300,
    }),
  }),
});


export const { useGetIncentivesDataQuery } = incentiveList;
