import * as Yup from "yup";

export const LeadSchemaAdmin = Yup.object().shape({
  source: Yup.string().min(3).optional().label("Source"),
  name: Yup.string().min(3).required("Project is Required"),
  mobile: Yup.number().min(5).required("Mobile is Required"),
  code: Yup.string().required("Code is Required"),
  clientName: Yup.string().min(3).required("Client Name is Required"),
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Client Email is Required"),
  type: Yup.string().required("Type is required"),
  whatsapp: Yup.string().min(5).required("Whatsapp is Required"),
  srManager: Yup.string().required("Sr Manager is required"),
});

export const LeadSchema = Yup.object().shape({
  source: Yup.string().min(3).optional().label("Source"),
  name: Yup.string().min(3).required("Name is Required"),
  mobile: Yup.number().min(5).required("Mobile is Required"),
  code: Yup.string().required("Code is Required"),
  clientName: Yup.string().min(3).required("Client Name is Required"),
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Client Email is Required"),
  type: Yup.string().required("Type is required"),
  whatsapp: Yup.string().min(5).required("Whatsapp is Required"),
  srManager: Yup.string(),
});

export const MeetingSchema = Yup.object().shape({
  lead: Yup.string().required("Lead is required"),
  productPitch: Yup.string().required("Product Pitch is required"),
  clientAddress: Yup.string().required("Client Address is required"),
  clientCity: Yup.string().required("Client City is required"),
  clientCountry: Yup.string().required("Client Country is required"),
  status: Yup.string().required("Status is required"),
  scheduleDate: Yup.date(),
  location: Yup.string().required("Meeting Location is required"),
  remarks: Yup.string().required("Remarks is required"),
  agents: Yup.array(),
});

export const BookingSchema = Yup.object().shape({
  developer: Yup.string(),
  relationshipManager: Yup.string(),
  meeting: Yup.string().required("Meeting is required"),
  projectName: Yup.string().required("Project Name is required"),
  unit: Yup.string().required("Unit is required"),
  areaSQFT: Yup.number()
    .min(100, "Area SQFT is required")
    .required("Area SQFT is required"),
  total: Yup.number()
    .min(1000, "Total must be greater than or equals to 1000")
    .required("Total is required"),
  propertyDetails: Yup.string().required("Booking Details is required"),
  paymentPlan: Yup.string().required("Payment Plan is required"),
  paymentMode: Yup.string().required("Payment Mode is required"),
  token: Yup.boolean().required("Token is required"),
  bookingAmount: Yup.number().required("Booking Amount is required"),
  // passback: Yup.number().required("Passback is required"),
  // passbackPerc: Yup.number(),
  clientLoyalty: Yup.number().required("Client Loyalty is required"),
  clientLoyaltyPerc: Yup.number().required("Client Loyalty % is required"),
  brokerReferral: Yup.number().required("Broker Referral is required"),
  brokerReferralPerc: Yup.number().required("Client Loyalty % is required"),
  commission: Yup.number().required("Commission is required"),
  commissionPerc: Yup.number(),
  date: Yup.date(),
  inputStatus: Yup.string(),
  agents: Yup.array(),
});

export const UserSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  role: Yup.string().required("Role is required"),
});

export const TeamSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  srManagerId: Yup.string().required("Sr Manager is required"),
  managerId: Yup.string(),
  // managerId: Yup.string().required("Manager is required"),
  teamLeadId: Yup.string(),
  agents: Yup.array(),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().min(8).required("Old Password is required"),
  newPassword: Yup.string().min(8).required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const ForgetChangeSchema = Yup.object().shape({
  password: Yup.string().min(8).required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const RescheduleSchema = Yup.object().shape({
  scheduleDate: Yup.string().required("Date and Time is required"),
  location: Yup.string().required("Location is required"),
  remarks: Yup.string().required("Remarks is required"),
});

export const HrmsAddUserSchema = Yup.object().shape({
  name: Yup.string().required("First Name is Required"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  //role: Yup.string().required("Role is Required")
});
