import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const incentiveDetailsApi = createApi({
  reducerPath: "incentiveDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["IncentiveDetail"],
  endpoints: (builder) => ({
    getIncentiveDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.incentiveDetails}/${id}`,
      }),
      providesTags: ["IncentiveDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetIncentiveDetailsByIdQuery } = incentiveDetailsApi;
