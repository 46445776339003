import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const leadsApi = createApi({
  reducerPath: "leadList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Leads"],
  endpoints: (builder) => ({
    getLeadsData: builder.query({
      query: ({
        page = 1,
        limit,
        type,
        status,
        individual,
        search,
        pagination = true,
        srManager,
        manager,
        assistantManager,
        teamLead,
        agent,
        startDate,
        endDate,
        skipType,
        projectId,
      }) => ({
        url: Apis.leads,
        params: {
          page,
          limit,
          type,
          status,
          individual,
          search,
          pagination,
          srManager,
          manager,
          assistantManager,
          teamLead,
          agent,
          startDate,
          endDate,
          skipType,
          projectId,
        },
      }),
      providesTags: ["Leads"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetLeadsDataQuery } = leadsApi;

export const assignedHistory = createApi({
  reducerPath: "assignedHistory",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Assigned"],
  endpoints: (builder) => ({
    getAssignedHistory: builder.query({
      query: (id) => ({
        url: `${Apis.assignedHistoryById}/${id}`,
      }),
      providesTags: ["LeadDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetAssignedHistoryQuery } = assignedHistory;

export const statusHistory = createApi({
  reducerPath: "statusHistory",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Lead Status"],
  endpoints: (builder) => ({
    getStatusHistory: builder.query({
      query: (id) => ({
        url: `${Apis.statusHistoryById}/${id}`,
      }),
      providesTags: ["LeadDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetStatusHistoryQuery } = statusHistory;
