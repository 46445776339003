import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";


export const leadPoolApi = createApi({
  reducerPath: "leadPoolList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["LeadPool"],
  endpoints: (builder) => ({
    getLeadPoolData: builder.query({
      query: ({ page = 1, limit, type, search, status, pagination = true }) => ({
        url: Apis.leadPool,
        params: { page, limit, type, search, status, pagination },
      }),
      providesTags: ["LeadPool"],
      keepUnusedDataFor: 300,
    }),
  }),
});

// Export the generated hook
export const { useGetLeadPoolDataQuery } = leadPoolApi;


